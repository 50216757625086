var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-4 col-12"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12"},[_c('b-button',{attrs:{"variant":"primary","data-action-type":"new"},on:{"click":function (v) {
              this$1.$router.push({ name: 'bankDeposit-transactions-new' });
            }}},[_vm._v(" "+_vm._s(_vm.$t("new"))+" ")]),_c('vue-excel-xlsx',{staticClass:"btn btn-relief-success ml-1 p-0 ptn-sm",attrs:{"data":_vm.itemsArray,"columns":_vm.tableColumns,"filename":this.$route.name,"sheetname":'xlsxSheet'}},[_c('img',{staticStyle:{"height":"39px","width":"auto"},attrs:{"src":require("@/assets/images/icons/xls.jpg"),"alt":""}})]),_c('b-button',{staticClass:"ml-1 p-0 ptn-sm",attrs:{"variant":"relief-danger"},on:{"click":_vm.printDocument}},[_c('img',{staticStyle:{"height":"39px","width":"auto"},attrs:{"src":require("@/assets/images/icons/pdf.jpg"),"alt":""}})])],1)])]),_c('g-table',{ref:"transaction-table",attrs:{"items":_vm.itemsProvider,"columns":_vm.tableColumns,"is-busy":_vm.isTableBusy,"foot-clone":"","noAction":true,"perPage":"25","filter":_vm.searchQuery,"totalRows":_vm.totalRows,"createButton":{ visiable: false },"excelButton":{ visiable: false },"pdfButton":{ visiable: false },"searchInput":{ visiable: true }},scopedSlots:_vm._u([(_vm.hideActions === false)?{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('print')),expression:"$t('print')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){return _vm.print(item.id)}}},[_c('feather-icon',{attrs:{"id":("invoice-row-" + (item.id) + "-preview-icon"),"icon":"PrinterIcon","stroke":"green"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('edit')),expression:"$t('edit')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"data-action-type":"edit","variant":"flat-primary","size":"sm"},on:{"click":function () {
              _vm.edit(item);
            }}},[_c('feather-icon',{attrs:{"id":("invoice-row-" + (item.id) + "-preview-icon"),"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"data-action-type":"delete","variant":"flat-danger","size":"sm"},on:{"click":function (v) {
              _vm.remove(item);
            }}},[_c('feather-icon',{staticClass:"danger",attrs:{"id":("invoice-row-" + (item.id) + "-delete-icon"),"icon":"TrashIcon","stroke":"red"}})],1)],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }