<template>
  <b-card>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-4 col-12"></div>
        <div
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12"
        >
          <b-button
            variant="primary"
            data-action-type="new"
            @click="
              (v) => {
                this.$router.push({ name: 'bankDeposit-transactions-new' });
              }
            "
          >
            {{ $t("new") }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px; width: auto"
            />
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px; width: auto"
            />
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="transaction-table"
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :filter="searchQuery"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: true }"
    >
      <template #actions="{ item }" v-if="hideActions === false">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="print(item.id)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            data-action-type="edit"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              () => {
                edit(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from "@/pages/Shared/Table.vue";
import reportMixin from "@/mixin/reportMixin";

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      filterOn: [],
      filter: {
        orderClause: null,
      },

      items: [],
      banks: [],
      pathName: this.$route.name,
      totalVals: 0,
      itemsArray: [],
      hideActions: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "code",
          field: "code",
          label: this.$t("code"),
          sortable: false,
        },
        {
          key: "bankName",
          field: "bankName",
          label: this.$t("bank"),
          sortable: false,
        },
        {
          key: "transactionDate",
          field: "transactionDate",
          label: this.$t("date"),
          sortable: false,
        },
        {
          key: "value",
          field: "value",
          label: this.$t("value"),
          sortable: true,
          type: "number",
          footer: () => this.fraction(this.totalVals),
        },
        {
          key: "notes",
          field: "notes",
          label: this.$t("notes"),
          sortable: false,
        },
        { key: "actions" },
      ];
    },
  },
  watch: {
    "$route.name": "refreshItems",
  },
  mounted() {},
  methods: {
    hide() {
      this.hideActions = true;
    },

    itemsProvider(ctx, callback) {
      const { currentPage, perPage, sortBy, sortDesc } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `BankDeposits${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            this.totalVals + item.value;
            item.transactionDate = this.getDate(item.transactionDate);
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide();
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshItems() {
      this.$refs["transaction-table"].refreshTable();
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t("areYouSureYouWantToDelete"),
        },
        () => {
          this.delete({
            url: "BankDeposits",
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t("deletedSuccessfully") });
            this.refreshItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: "bankDeposit-transactions-edit",
        params: { id: item.id },
      });
    },

    print(depositeId) {
      const reportName = 'BankDeposite-ar';
      const printedItem = {
        id: depositeId,
      }
      this.printReport(reportName, printedItem);
    },
  },
};
</script>

<style></style>
